import React from 'react'

const Protection = () => {

  return (
    <>
      <div className='w-screen  h-fit  grid justify-items-center gap-5 lg:gap-0 p-4'>
        <div className='grid grid-rows-2 h-fit pb-2 '>
          <div className='font-head2 text-5xl lg:text-6xl text-center '>PROTECTION</div>
          <div className='font-letter lg:text-xl  text-center '>Life and Protection Insurance policies can protect you and your family from the financial consequences of death, a serious accident or illness, or unemployment.</div>
        </div>
        <div className='w-full grid  gap-5 justify-items-center items-center lg:w-11/12 lg:grid-cols-3 '>
          <div className='border shadow-lg cursor-pointer h-full hover:shadow-2xl  rounded-xl overflow-hidden ' >
            <img src='../protection1.webp' className='w-full' alt=''></img>
            <div className='text-center text-xl font-letter p-5 text-orange-500'>CRITICAL ILLNESS INSURANCE</div>
            <div className='font-letter p-5 text-center '>Critical Illness Insurance pays out a tax-free lump sum on the diagnosis of certain life-threatening or debilitating (but not fatal) conditions including heart attack, stroke, cancer and major organ transplants.</div>

          </div>
          <div className='border shadow-lg cursor-pointer h-full hover:shadow-2xl rounded-xl overflow-hidden  ' >
            <img src='../protection2.webp' className='w-full' alt=''></img>
            <div className='text-center text-xl text-orange-500 font-letter p-5'>LIFE INSURANCE</div>
            <div className='p-3 font-letter text-center'>Life Insurance (sometimes known as Life Assurance) helps provide financial security for people who depend on you, should you die.</div>
            <div className='p-1 font-letter pb-2 text-center'>Although money can’t replace a loved one, it can help those left behind to weather the financial storm. For example, it could pay off the mortgage or provide an income to help cover regular household expenditure.</div>

          </div>
            <div className='border shadow-lg cursor-pointer h-full  hover:shadow-2xl rounded-xl overflow-hidden  ' >
            <img src='../protection3.webp' className='w-full' alt=''></img>
            <div className='text-center font-letter text-xl text-orange-500 p-5'>INCOME PROTECTION</div>
            <div className='p-3 font-letter text-center'>Income Protection Insurance pays out a regular tax-free replacement income if you become unable to work because of illness, injury or, with certain policies, unemployment.</div>
            <div className='p-5 font-letter text-center'>It could help you keep up with your mortgage repayments and other living costs until you’re able to return to work.</div>

          </div>
          <div>

          </div>
        </div>
      </div>
    </>
  )
}

export default Protection