import React from 'react'
import { useNavigate } from 'react-router-dom'

const Landingpage = () => {
    const Navigate = useNavigate()
  return (
    <>
    <div className='w-screen h-screen grid bg-stone-100 '>
    {/* <ul className=' grid grid-cols-4 md:text-center md:gap-2 md:right-12  md:scale-100  mt-0 md:absolute bg-black  text-white  md:text-gray-600 md:bg-inherit right-20 top-6 '>
            <li>Contact</li>
            <li>Protection</li>
            <li>Mortgages</li>
            <li>Further Information</li>
          </ul> */}
      <div className=' flex items-center  '>
          <img alt='logo' src='../LOGO1.jpg' className='w-36 mb-5'></img>
          <div className='mb-5'>
          <div className='font-letter    text-center md:text-start  text-xl'>PARUL CHHABRA</div>
          <div className='text-xs w-44 md:w-full md:text-end  font-abc text-center'>Mortgage & Protection Advice at APSM Financial Services</div>
          </div>

       
          
      </div>
      <div className='w-full grid grid-rows-2 mt-5 md:grid-rows-1 md:gap-5 md:grid-cols-2 md:w-10/12 pb-10'>
        <div>
            <div className='text-5xl ml-5 font-head   '>Hello</div>
            <div className='font-bold ml-5 font-abc md:text-3xl '>A Bit About Me</div>
            <div className='text-xs ml-5 font-letter md:text-xl'>My name is Parul Chhabra: a friendly and professional Staines based Mortgage & Protection Adviser serving Middlesex and the wider South East.</div>
            <div className='text-xs ml-5 mt-3  font-letter  md:text-xl'>I am founder of APSM Financial Services and have 13 yrs of experience within the industry. I pride myself on both the advice and the level of service I give.</div>
            <div className='text-xs ml-5 mt-3 font-letter md:text-xl '>Please get in touch if you would like a fee-free review of either your mortgage or protection requirements/needs. I am able to arrange appointments to suit you, at your home or office, including evenings.</div>

            <div className='w-full grid gap-2 grid-cols-5 mt-5 justify-items-center items-center'>
          <div className='w-20 text-center h-20 md:w-28 md:h-28  rounded-full hover:bg-white transition-colors font-head  grid items-center bg-yellow-500 border border-black cursor-pointer ' onClick={()=>{Navigate("/Mortgages")}}>Mortgages</div>
          <div className='w-20 text-center h-20 md:w-28 md:h-28 rounded-full  hover:bg-white transition-colors font-head grid items-center bg-red-500 border border-black cursor-pointer ' onClick={()=>{Navigate("/Protection")}}>Protection</div>
          <div className='w-20 text-center h-20 md:w-28 md:h-28 rounded-full  hover:bg-white transition-colors font-head grid items-center bg-orange-500 border border-black cursor-pointer '  onClick={()=>{Navigate("/Lending")}}>Special Lending</div>
          <div className='w-20 text-center h-20 md:w-28 md:h-28 rounded-full  hover:bg-white transition-colors font-head grid items-center bg-blue-300 border border-black cursor-pointer ' onClick={()=>{Navigate("/Contact")}}>Contact</div>
          <div className='w-20 text-center h-20 md:w-28 md:h-28 rounded-full  hover:bg-white transition-colors font-head grid items-center bg-green-300 border border-black cursor-pointer '  onClick={()=>{Navigate("/MoreInfo")}}>More Info</div>
            </div>
        </div>

        <div className='w-full grid justify-items-center items-center h-fit mt-5 md:justify-items-end   pb-5 md:order-first  '>
            <img src='../profile.jpg' className='  rounded-full w-72  md:w-7/12'  alt='image'></img>
        </div> 
        
       
      </div>
     
      <div className=' grid grid-row-3  w-full border pt-4  '>
      <div className='w-full grid grid-row-3 md:grid-rows-none md:grid-cols-3 md:justify-items-center md:items-center'>
      <div className=' ml-5 md:ml-0 grid   md:items-center' >
                <div className='font-bold '>Phone</div>
                <div className='font-letter'>+44 7951 054958</div>
            </div>
            <div className='ml-5 md:ml-0'>
                <div className='font-bold'>Email</div>
                <div  className='font-letter'>parul.chhabra@apsmfs.co.uk</div>
            </div>
            <div className='ml-5 md:ml-0 '>
                
            <div className='font-bold'>Address </div>
            <div className='font-letter'>41 Wapshott Road
Staines Upon Thames 
TW18 3HB
</div>
            </div>
      </div>


      </div>
        {/* <div className='w-full grid grid-row-3 gap-2 md:scale-0'>
            <div className='ml-5'>
                <div className='font-bold '>Phone</div>
                <div className='font-letter'>+44 7951 054958</div>
            </div>
            <div className='ml-5'>
                <div className='font-bold'>Email</div>
                <div  className='font-letter'>parul.chhabra@aitana.co.uk</div>
            </div>
            <div className='ml-5 mb-5'>
                
            <div className='font-bold '>Adress </div>
            <div className='font-letter'>Aitana Financial Services, Wises Oast, Wises Lane, ME9 8LR</div>
            </div>

        </div> */}
   

    </div>
    </>
  )
}

export default Landingpage