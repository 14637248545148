
import { Route, Routes } from 'react-router-dom';
import './App.css';
import AboutLanding from './Components/AboutLanding';
import Contact from './Components/Contact';
import Landingpage from './Components/Landingpage';
import MoreInfo from './Components/MoreInfo';
import Mortgages from './Components/Mortgages';

import Protection from './Components/Protection';


function App() {
  return (
    <div className="App">
     
    <Routes>
      
      <Route   path='/'  element={<Landingpage/>} />
      <Route   path='/Contact'  element={<Contact/>} />
      <Route  path='/Mortgages' element={<Mortgages/>} />
      <Route  path='/Protection' element={<Protection/>} />
      <Route  path='/MoreInfo' element={<MoreInfo/>} />
      <Route  path='/Lending' element={<AboutLanding/>} />

    </Routes>

    </div>
  );
}

export default App;
