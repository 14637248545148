import React from 'react'
import { useNavigate } from 'react-router-dom'

const AboutLanding = () => {
   const Navigate = useNavigate()
  return (
    <>
    <div className='w-full h-full md:h-screen justify-items-center grid grid-rows-6'>
        <div>
            <div className=' text-5xl text-orange-500  text-center font-head2 md:text-5xl'>SPECIALIST LENDING</div>
        </div>
        <div>
            <div className='text-center  font-letter'>If you’re looking for buy to let, second charge loans, or bridging finance, you’ll have specialist requirements that set you apart from a standard residential purchaser</div>
        </div>
            <div className='text-center font-letter md:w-11/12'>We can access second charge loans and bridging finance through Enterprise Finance. We do not advise on second charge mortgages. If you need a second charge mortgage we will refer you to Enterprise Finance, a master broker for second charges, who will be able to advise you.</div>
            <div className='text-center font-letter '>​Some buy to let mortgages and Bridging finance are not regulated by the Financial Conduct Authority</div>
            <div className='text-xl font-head2'> Parul Chhabra</div>
              <div className='w-full h-fit grid justify-items-center '>
              <button className=" bg-transparent hover:bg-orange-300 text-orange-300 font-semibold hover:text-white py-2 px-4 border border-orange-300 hover:border-transparent rounded mb-4 w-1/3"
         
         onClick={()=>{Navigate("/Contact")}}  > Get In Touch  </button> 
              </div>
    </div>
    </>
  )
}

export default AboutLanding