import React from 'react'
import { useNavigate } from 'react-router-dom'

const MortgagesInfo = () => {

  const data1=[
    {
      title:'FIXED RATE',
      desc:'With a fixed rate mortgage the rate stays the same, so your payments are set at a certain level for an agreed period. At the end of that period, the lender will usually switch you onto its SVR (see ‘Variable rate’). You may have to pay a penalty to leave your lender, especially during the fixed rate period. You may also have to pay an early repayment charge if you pay back extra amounts during the fixed rate period. A fixed rate mortgage makes budgeting much easier because your payments will stay the same - even if interest rates go up. However, it also means you won’t benefit if rates go down.'
    },
    {
      title:'TRACKER RATE',
      desc:'Your monthly payment fluctuates in line with a rate that’s lower, or more likely higher than a chosen Base Rate (usually the Bank of England Base Rate). The rate charged on the mortgage ‘tracks’ that rate, usually for a set period of two to three years. You may have to pay a penalty to leave your lender, especially during the tracker period. You may also have to pay an early repayment charge if you over pay extra amounts during the tracker period. A tracker may suit you if you can afford to pay more when interest rates go up – and you’ll benefit when they go down. It’s not a good choice if your budget won’t stretch to higher monthly payments.',
    },
    {
      title:'FLEXIBLE MORTGAGES',
      desc:'These schemes allow you to overpay, underpay or even take a payment ‘holiday’. Any unpaid interest will be added to the outstanding mortgage; any overpayment will reduce it. Some have the facility to draw down additional funds to a pre-agreed limit.',
    },
    {
      title:'GOVERNMENT-BACKED SCHEMES',
      desc:'ver recent years the government has backed a number of schemes – such as ‘Help to Buy’ – to support homebuyers. We can explain the details of these schemes and whether you can benefit from them. Please see our Help to Buy guide for more details.',
    },
    {
      title:'DISCOUNTED RATE',
      desc:'Like a variable rate mortgage, your monthly payments can go up or down. However, you’ll get a discount on the lender’s SVR for a set period of time, after which you’ll usually switch to the full SVR. You may have to pay a penalty for overpayments and early repayment, and the lender may choose not to reduce (or delay reducing) its variable rate – even if the Base Rate goes down. Discounted rate mortgages can give you a gentler start to your mortgage, at a time when money may be tight. However, you must be confident you can afford the payments when the discount ends and the rate increases',
    },
    {
      title:'OFFSET MORTGAGES',
      desc:'Taking out an offset mortgage enables you to use your savings to reduce your mortgage balance and the interest you pay on it. For example, if you borrowed £200,000, but had £50,000 in savings, you would only be paying interest on £150,000. Offset mortgages are generally more expensive than standard deals, but can reduce your monthly payments, whilst still giving you access to your savings',
    },
  ]

  const Card=({title,desc})=>{
    return(
    <div>
      <h4 className='col-span-1 text-xl font-bold text-orange-400'>{title}</h4>
      <p className='text-lg'>{desc}</p>
    </div>
    )
  }
  const Navigate = useNavigate()
  return (
    <>
      <div className='w-full h-fit grid justify-items-center items-center gap-5 '>
        <div className='w-full grid justify-items-center'>
          <div className=' text-3xl font-letter text-center md:text-6xl'>MORTGAGE ADVICE YOU CAN DEPEND ON</div>
          <div className='font-letter text-lg text-center md:px-40'>Whether buying your first home, buying to let, or remortgaging it’s a big commitment. I aim to help you understand what you need to think about making you feel more confident about your financial decisions.</div>
        </div>
        <div className='grid justify-items-center'>
          <div className=' text-3xl md:text-5xl text-orange-400 font-abc'>TYPES OF MORTGAGES</div>
          <div className='md:text-xl text-lg font-letter md:w-11/12 text-center'>Before you choose a specific deal, you need to decide what type of mortgage is the most appropriate for your needs.
            Variable rate Your monthly payment fluctuates in line with a Standard Variable Rate (SVR) of interest, set by the lender. You probably won’t get penalised if you decide to change lenders and you may be able to repay additional amounts without penalty too. Many lenders won’t offer their standard variable rate to new borrowers</div>
        </div>

        <div className='  p-4 grid grid-cols-1 md:grid-cols-2 gap-4'>
          {data1.map((item,i)=><Card key={i} title={item.title} desc={item.desc} />)}


        </div>

        <div className='grid justify-items-center gap-5 md:w-11/12'>
          <div className='font-abc text-orange-400 text-3xl md:text-5xl'>REPAYMENT METHODS</div>
          <div className='font-letter text-lg text-center'>The two most common ways of repaying your mortgage are capital repayment and interest only.</div>
          <div className='grid grid-rows-2 md:grid-rows-none gap-5 md:grid-cols-2'>
            <div className='grid gap-5'>
              <div className='font-letter text-orange-400'>CAPITAL REPAYMENT</div>
              <div className='font-letter'>On a repayment mortgage your monthly payments will comprise a portion to pay the interest on the money you’ve borrowed, as well as a portion to repay the capital sum (the amount you borrowed). The benefit of capital repayment is that you can see the mortgage reducing each year (albeit very slowly in the early years) and you are guaranteed to repay the debt at the end of the mortgage term, as long as payments are maintained. On a capital repayment mortgage the shorter the term you pay your mortgage over the bigger the monthly payment will be. By having a longer term you may benefit from a lower monthly payment but you will pay more interest to the lender over the term. You will need to think about how soon you want to be ‘mortgage free’ and balance this up with the mortgage term that makes the monthly payments affordable.</div>
            </div>


            <div className='grid    gap-5 '>
              <div className='font-letter text-orange-400'>INTEREST ONLY</div>
              <div className='font-letter'>If you opt for an interest only loan, your monthly payments will only cover the interest on the mortgage balance. The capital (the amount you borrowed) will remain the same and will need to be repaid at the end of your mortgage term. This means you will need a separate investment or combination of investments to generate the capital required, and you will need to prove that you can afford to do this. The value of investments can go down as well as up and you may not get back the original amount invested. For an interest only mortgage, the lender will need to see your plan for repaying the loan when the interest only period ends. If you fail to generate enough to repay your mortgage by the end of the mortgage term, you may be forced to sell your property.</div>
            </div>

          </div>
        </div>

        <div className='w-full grid justify-items-center'> <button className=" bg-transparent hover:bg-orange-300 text-orange-300 font-semibold hover:text-white py-2 px-4 border border-orange-300 hover:border-transparent rounded mb-4  w-11/12 md:w-1/3" onClick={() => { Navigate("/Contact") }}

        > Get In Touch</button> </div>
        <div className='bg-black p-8 text-white w-full text-center  font-abc'>Your home may be repossessed if you do not keep up repayments on your mortgage </div>
      </div>

    </>
  )
}

export default MortgagesInfo